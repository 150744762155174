import React from "react";
import Image from "components/shared/Image";
import { Button } from "components/shared/Button";
import logoIcon from "assets/images/tl_logo.svg";
import userIcon from "assets/images/user_icon.svg";
import complianceButtonIcon from "assets/images/compliance_button_icon.svg";
import "./Header.scss";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div className="mainheader__wrap">
        <div className="mainheader__logo">
          <Image onClick={() => this.props.clearChat()} src={logoIcon} alt="logo" />
          <div className="mainheader__logo-text">
            <p>External Experts Transcript Library</p>
            <h1>AI-powered transcript assistant</h1>
          </div>
        </div>
        <div className="mainheader__userProfile">
          <Image src={userIcon} alt="logo" />
        </div>
        <div className="mainheader__compliance">
          <a rel="noopener noreferrer" href={"https://bcgcloudasp.sharepoint.com/:p:/r/sites/Case_Team_Services/_layouts/15/Doc.aspx?sourcedoc=%7BDC5A795E-A3B6-4DC6-A089-A4D670DAFED1%7D&file=Compliance%20Overview%20Slide.pptx&action=edit&mobileredirect=true"}
            target="_blank" >
            <Button className="compliance-button">
              <Image className="compliance-icon" src={complianceButtonIcon} alt="complianceButtonIcon" />
              {"Compliance"}</Button>
          </a>
        </div>
      </div>
    );
  }
}

Header.prototypes = {
  // finalSuccess: PropTypes.func
}

export { Header };
