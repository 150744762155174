import React, { useEffect, useState } from "react";
import "./TranscriptDetailed.scss";
import DownloadIcon from "assets/images/Download1.svg";
import docvizNotAvailableImage from "assets/images/docviz_preview_not_available_2.png";
import { selectors as responseSelector } from "../../redux/reducers/response/response_reducer";
import { selectors as docvizSelector } from "../../redux/reducers/docviz/docviz_reducer";
import { selectors as taxonomySelector } from "../../redux/reducers/taxonomy/taxonomy_reducer";
import Image from "components/shared/Image";
import { Tab, Table, MenuItem, Label } from 'semantic-ui-react'
import { getFormattedDate, getMonthYearFromDate } from "utils/helpers/helpers";
import { RESPONSE_DATA, DOCVIZ } from "redux/constants";
import { useDispatch, useSelector } from "react-redux";
import noExpertEmployementHistory from "assets/images/no_expert_employement_history.svg";
import expandTagsImage from "assets/images/ExpandTags.svg";
import exclamationIcon from "assets/images/exclamation_icon.svg";
import collapseTagsImage from "assets/images/CollapseTags.svg";
import rightArrowFilledImage from "assets/images/right-arrow-filled.svg";
import { Docviz } from "../Docviz";
import Skeleton from 'react-loading-skeleton';
import CONFIG from 'config';
import { Tooltip } from "components/shared/Tooltip";

// Analytics
import ANALYTICS from "utils/analytics/analytics";
import {
    PAGES,
    PRIMARY_CATEGORY,
    DTMRULE
} from "utils/analytics/analytics_constants";


const {
    GET_FILE
} = RESPONSE_DATA;

const {
    GET_DOCVIZ_METADATA
} = DOCVIZ;

const {
    TBDB_IDS: {
        SEARCH_SUBJECT,
        FPA,
        IPA,
        BCG_INTERNAL
    }
} = CONFIG;
export const TranscriptDetailed = ({
    transcript,
    lastUserQues,
    companyFilterApplied,
    monthOfInterviewRangeValue,
    companyList,
    isDownloadEnabled = false,
    sourceFilterApplied,
    industryFilterApplied,
    functionFilterApplied,
    industryTagsList,
    functionTagsList
}) => {
    const doc = useSelector(docvizSelector.getDocvizDocument);
    const docvizLoading = useSelector(docvizSelector.getLoading);
    const taxonomyLoading = useSelector(taxonomySelector.getLoading);
    const internalTagsAPILoading = useSelector(responseSelector.getInternalTagsAPILoading);
    const FPAtags = useSelector((state) => taxonomySelector.getParentTagsData(state, FPA));
    const IPAtags = useSelector((state) => taxonomySelector.getParentTagsData(state, IPA));
    const dispatch = useDispatch();
    const isChatBotScreen = window.location.pathname === '/';
    const pageURL = window.location.href;
    useEffect(() => {
        if (transcript.docvizRequestId)
            dispatch({ type: GET_DOCVIZ_METADATA, payload: { id: transcript.docvizRequestId, meeting_id: transcript.meeting_id } });
    }, [transcript.docvizRequestId]);

    const downloadFile = (transcript, trackClick) => {
        if (trackClick) {
            const newAdobeData = {
                page: {
                    category: {
                        primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                    },
                    pageInfo: {
                        pageName: window.location.pathname === '/' ? PAGES.Transcript_Library_Keyword_Search : PAGES.Detailed_Transcript_View,
                        previousPageName: window.location.pathname === '/' ? PAGES.Transcript_Library_Keyword_Search : PAGES.Detailed_Transcript_View,
                        pageURL: pageURL
                    }
                },
                internal: {
                    search: { term: lastUserQues }
                },
                iconName: "Download",
                link: { toolSearch: transcript.meeting_id },
                filterCategory: "",
                filterChecked: ""
            };
            if (companyFilterApplied > 0) {
                newAdobeData.filterCategory += "Company Name | ";
                newAdobeData.filterChecked += companyList.join(" , ") + " | ";
            }
            if (monthOfInterviewRangeValue !== '') {
                newAdobeData.filterCategory += "Month of Interview | ";
                newAdobeData.filterChecked += monthOfInterviewRangeValue + " | ";
            }
            if (sourceFilterApplied.length > 0) {
                newAdobeData.filterCategory += "Source | ";
                newAdobeData.filterChecked += sourceFilterApplied.map(i => i.key).join(" , ") + " | ";
            }
            if (industryFilterApplied > 0) {
                newAdobeData.filterCategory += "IndustryPA | ";
                newAdobeData.filterChecked += industryTagsList.join(" , ") + " | ";
            }
            if (functionFilterApplied > 0) {
                newAdobeData.filterCategory += "FunctionPA | ";
                newAdobeData.filterChecked += functionTagsList.join(" , ") + " | ";
            }
            ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.ICON_ACTION_CLICKS);
        }
        if (!transcript.kpId) {
            transcript.kpId = transcript.meeting_id;
            transcript.fileName = transcript.sanitized_attachment_name.replace(/(\r\n|\n|\r)/gm, "") + ".docx"
        }
        dispatch({ type: GET_FILE, payload: { transcript } });
    };
    const panes = [
        {
            menuItem: 'Summary',
            render: () => <Tab.Pane attached={false}><SummaryTab transcript={transcript} /></Tab.Pane>,
        },
        {
            menuItem: 'Expert Employment History',
            render: () => <Tab.Pane attached={false}><ExpertEmploymentTab transcript={transcript} /></Tab.Pane>,
        }
    ]

    const taxonomyPanes = [
        {
            menuItem: (
                <MenuItem key='AssociatedPATopicSector'>
                    Associated PA/Topic/Sector<span className="taxonomy-count">{IPAtags.length + FPAtags.length}</span>
                </MenuItem>
            ),
            render: () => <Tab.Pane attached={false}><Tags tags={["Innovation", "Innovation Strategy", "Retail"]} /></Tab.Pane>,
        }
    ]

    const Tags = ({ tags }) => {
        const [expanded, setExpanded] = useState(true);
        const handleExpandClick = () => {
            setExpanded(!expanded);
        }
        return (
            <div className="tags-container">
                {(!taxonomyLoading || (IPAtags.length + FPAtags.length) > 0) && <div className="tags-disclaimer">
                    <Image className="tags-disclaimer__info-icon" src={exclamationIcon} alt="exclamationIcon" />
                    <span className="tags-disclaimer__info-text">{
                        (IPAtags.length + FPAtags.length) > 0
                            ? <span>These tags are generated by GenAI. Help us enhance their accuracy by sharing your inputs through the <b>{`'Feedback'`}</b> button.</span>
                            : 'Tags are yet to be generated by GenAI'}</span>
                </div>}
                <div className="tags">
                    {
                        IPAtags.length > 0 &&
                        <div className="tags-heading">
                            <span>Industry</span>
                        </div>
                    }
                    {(IPAtags.length + FPAtags.length) > 0 && <div className="action-container">
                        <div className="action">
                            <span className="expand_collapse_text">{expanded ? "Collapse Tags" : "Expand Tags"}</span>
                            <Image src={expanded ? collapseTagsImage : expandTagsImage} alt="expandTagsImage" onClick={handleExpandClick} />
                        </div>
                    </div>}
                    {
                        IPAtags.map((IPAtag, index) => (
                            <div className="tag" key={index}>
                                <>
                                    {
                                        IPAtag.map((tag, index) => {
                                            const isLeafNode = index === (IPAtag.length - 1)
                                            return (expanded || isLeafNode) && <span>
                                                <span className={isLeafNode ? "main-tag" : "parent-tag"}>{tag.name}</span>
                                                {!isLeafNode && <Image className="right-arrow" src={rightArrowFilledImage} alt="rightArrowFilledImage" />}
                                            </span>
                                        })
                                    }
                                </>
                            </div>
                        ))
                    }
                    {
                        FPAtags.length > 0 &&
                        <div className="tags-heading">
                            <span>Function</span>
                        </div>
                    }
                    {
                        FPAtags.map((FPAtag, index) => (
                            <div className="tag" key={index}>
                                <>
                                    {
                                        FPAtag.map((tag, index) => {
                                            const isLeafNode = index === (FPAtag.length - 1)
                                            return (expanded || isLeafNode) && <span>
                                                <span className={isLeafNode ? "main-tag" : "parent-tag"}>{tag.name}</span>
                                                {!isLeafNode && <Image className="right-arrow" src={rightArrowFilledImage} alt="rightArrowFilledImage" />}
                                            </span>
                                        })
                                    }
                                </>
                            </div>
                        ))
                    }

                </div>

            </div>
        )
    };
    const handleChange = (e, data) => {
        const isChatBotScreen = window.location.pathname === '/';
        const newAdobeData = {
            page: {
                category: {
                    primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                },
                pageInfo: {
                    pageName: isChatBotScreen ? PAGES.Transcript_Library_Keyword_Search : PAGES.Detailed_Transcript_View,
                    previousPageName: isChatBotScreen ? PAGES.Transcript_Library_Keyword_Search : PAGES.Detailed_Transcript_View,
                    pageURL: pageURL
                }
            },
            linkName: data.activeIndex === 0 ? "Summary" : "Expert Employment History",
            link: { toolSearch: transcript.meeting_id },
            filterCategory: "",
            filterChecked: ""
        };
        newAdobeData.internal = {
            search: { term: "NA" }
        };
        if (isChatBotScreen) {
            if (companyFilterApplied > 0) {
                newAdobeData.filterCategory += "Company Name | ";
                newAdobeData.filterChecked += companyList.join(" , ") + " | ";
            }
            if (monthOfInterviewRangeValue !== '') {
                newAdobeData.filterCategory += "Month of Interview | ";
                newAdobeData.filterChecked += monthOfInterviewRangeValue + " | ";
            }
            if (sourceFilterApplied.length > 0) {
                newAdobeData.filterCategory += "Source | ";
                newAdobeData.filterChecked += sourceFilterApplied.map(i => i.key).join(" , ") + " | ";
            }
            if (industryFilterApplied > 0) {
                newAdobeData.filterCategory += "IndustryPA | ";
                newAdobeData.filterChecked += industryTagsList.join(" , ") + " | ";
            }
            if (functionFilterApplied > 0) {
                newAdobeData.filterCategory += "FunctionPA | ";
                newAdobeData.filterChecked += functionTagsList.join(" , ") + " | ";
            }

            newAdobeData.internal = {
                search: { term: lastUserQues }
            };
        }

        ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.BUTTON_LINK_CLICKS);
    }

    const SummaryTab = ({ transcript }) => (
        <div>
            <div className="generated-summary">{transcript.generated_summary}</div>
            <div className="meta-data-wrapper-1">
                {/* <div className="meta-data 2">
                    <span>Relevancy | <b>{Math.round(transcript.relevance * 100)}%</b></span>
                </div> */}
                {transcript.downloadCount && <div className="meta-data 2">
                    <span>Download | <b>{transcript.downloadCount}</b></span>
                </div>}
                <div className="meta-data 3">

                    <span>Expert Region | <b>{transcript.expert_region && transcript.expert_region !== "null" ? transcript.expert_region : "-"}</b> </span>
                </div>
                <div className="meta-data 4">

                    <span>Duration | <b>{transcript.duration_of_interview && transcript.duration_of_interview !== "null" ? transcript.duration_of_interview + " mins" : "-"}</b></span>
                </div>

            </div>
            <div className="meta-data-wrapper-2">
                <div className="meta-data 1">
                    Source | <span className="source-pill">{transcript.vendor_source}</span>
                    {
                        transcript.vendor_source !== "Tegus" && !taxonomyLoading && !internalTagsAPILoading && transcript.downloadCount &&
                        <>
                            <span data-tip data-for='bcg-pill' className={`source-pill ${transcript.vendor_source === "Tegus" ? "non-bcg" : "bcg"}`}>
                                {transcript.vendor_source === "Tegus" ? "Non-BCG" : "BCG"}
                            </span>
                            <Tooltip id="bcg-pill" delayHide={0}>Calls Conducted by BCG</Tooltip>
                        </>
                    }
                </div>
                <div className="meta-data 2">
                    Date of interview <span className="date">| {getFormattedDate(new Date(transcript.date_of_interview * 1000))}</span>
                </div>
                <div className="meta-data 3">
                    Published date <span className="date">| {getFormattedDate(new Date(transcript.published_date * 1000))} </span>
                </div>

            </div>
            {
                transcript.vendor_source === "Tegus" && <div className="topics-container">
                    <div className="table-body">
                        <div className="table-row" key={1}>
                            <div className="table-item topics-header">{"Primary Topic Name"}</div>
                            <div className="table-item topics-value">{transcript.primaryTopicName ? transcript.primaryTopicName : "-"}</div>
                        </div>
                        <div className="table-row" key={2}>
                            <div className="table-item topics-header">{"Target Discussion Topics"}</div>
                            <div className="table-item topics-value">{transcript.targetDiscussionTopics && transcript.targetDiscussionTopics.length > 0 ? transcript.targetDiscussionTopics.join(", ") : "-"}</div>
                        </div>
                        <div className="table-row" key={3}>
                            <div className="table-item topics-header">{"Inferred Discussion Topics "}</div>
                            <div className="table-item topics-value">{transcript.inferredDiscussionTopics && transcript.inferredDiscussionTopics.length > 0 ? transcript.inferredDiscussionTopics.join(", ") : "-"}</div>
                        </div>
                    </div>
                </div>
            }
            <div className="taxonomy-container">
                <Tab menu={{ secondary: true, pointing: true }} panes={taxonomyPanes} onTabChange={handleChange} />
            </div>
        </div >
    )

    const ExpertEmploymentTab = ({ transcript }) => {
        const replaceWithBr = () => {
            return transcript.expert_biography ?
                transcript.expert_biography.replace(/\n/g, "<br />")
                : "-"

        }
        return (
            <div>
                <div className="EET-wrapper">
                    <div className="table-header">
                        <div className="table-row-heading">
                            <div className="table-item company">Company</div>
                            <div className="table-item designation">Designation</div>
                            {/* <div className="table-item date">Start date</div>
                    <div className="table-item date">End date</div> */}
                            <div className="table-item tenure">{`Expert's Tenure`}</div>
                        </div>
                    </div>
                    <div className="table-body">
                        {(transcript.expert_employment_history && transcript.expert_employment_history.length > 0) ?
                            (
                                transcript.expert_employment_history
                                    .sort((a, b) => new Date(b.employment_start_date) - new Date(a.employment_start_date))
                                    .map((eeh, index) => (
                                        <div className="table-row" key={index}>
                                            <div className="table-item company">{eeh.company && eeh.company !== "Not Available" ? eeh.company : '-'}</div>
                                            <div className="table-item designation">{eeh.sanitized_position && eeh.sanitized_position !== "null" ? eeh.sanitized_position : '-'}</div>
                                            {/* <div className="table-item date">{getMonthYearFromDate(new Date(eeh.employment_start_date))}</div>
                                <div className="table-item date">{eeh.employment_end_date === "Present" ? `Present (As of ${getMonthYearFromDate(new Date(transcript.date_of_transcript_ingestion * 1000))})` : getMonthYearFromDate(new Date(eeh.employment_end_date))}</div> */}
                                            <div className="table-item tenure">{eeh.tenure}</div>
                                        </div>
                                    ))
                            ) : (
                                <div className="no-expert-history">
                                    <Image src={noExpertEmployementHistory} alt="logo" />
                                    <div className="no-expert-history__text">
                                        <p>Expert information is unavailable for this transcript</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                </div>
                <div className="expert-biography-topic">
                    <div className="expert-biography-label">Expert Biography</div>
                    <div className="expert-biography-text" dangerouslySetInnerHTML={{ __html: replaceWithBr() }}></div>
                </div>
            </div>
        )
    };


    return (
        <div className="transcript-detalied-view-wrapper">
            {transcript.documentid &&
                <div className="meta-data-wrapper">
                    <div className="top-section">
                        <div className="generated-title">{transcript.generated_title}</div>
                        <div className="action-icons">
                            <div className="download-icon">
                                {/* <Image src={DownloadIcon} alt="logo" onClick={() => downloadFile(transcript)} /> */}
                            </div>
                        </div>
                    </div>
                    <div className="mid-section">
                        <div className="left-section">
                            <Tab menu={{ secondary: true, pointing: true }} panes={panes} onTabChange={handleChange} />
                        </div>
                        <div className="right-section">
                            <div className="docviz-preview">
                                {/* <Image src={DocvizPreview} alt="logo" /> */}
                                {
                                    transcript.docvizRequestId !== undefined ?
                                        (docvizLoading || !doc.baseURL ?
                                            <Skeleton height={200} /> : <Docviz doc={doc} transcript={transcript} isDownloadEnabled={isDownloadEnabled} downloadClicked={downloadFile} />) :
                                        <>
                                            <Image src={docvizNotAvailableImage} alt="docvizNotAvailable" />
                                            <div className="file-name-container">
                                                <p title={transcript.sanitized_attachment_name.replace(/(\r\n|\n|\r)/gm, "") + ".docx"} className={`file-name`}>{transcript.sanitized_attachment_name.replace(/(\r\n|\n|\r)/gm, "") + ".docx"}</p>
                                                {isDownloadEnabled && <Image className="download-icon-docviz" src={DownloadIcon} alt="DownloadIcon" onClick={() => downloadFile(transcript, true)} />}
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="tags-wrapper"></div>
        </div >
    );
};
