import React, { Fragment, PureComponent } from "react";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Image from "components/shared/Image";
import chatIcon from "assets/images/chat_icon_1.svg";
import chatInfoIcon from "assets/images/chat_info_icon.svg";
import KeywordSearchIcon from "assets/images/Keyword_Search.svg";
import { withRouter } from "react-router";
import CONFIG from "config";
import { ErrorBoundary } from "containers/ErrorBoundary/ErrorBoundary";
import { usabillaSetupFn } from "../../utils/usabilla/usabilla.min.js";
import { Tooltip } from "components/shared/Tooltip";
import { actions as peopleResultsActions } from 'redux/actions/peopleresults/peopleresults_actions';
import { selectors as entitlementSelectors } from "redux/reducers/authorization/authorization_reducer";
import "./Layout.scss";
import { setLoggedInUserData } from "redux/global.action";
import { Header } from "components/Header";
import { Entitlement401RedirectWrapper } from "containers/Entitlement401RedirectWrapper";
import { getUserId } from "utils/auth/auth";
import { AcknowledgementModal } from "components/AcknowledgementModal";
import { getRetrievalSuccess, clearTranscriptSelected, chatClearAllMessages, chatSuccess, setChatHistoryID } from "redux/actions/response/response.actions";
import { resetAllParentTags } from "redux/actions/taxonomy/Taxonomy.actions";
import { sendToLocalStorage } from 'utils/localStorage/localStorage';
import CONSTANTS from "globalConstants";

const { ENTITLEMENTS: { TRANSCRIPT_LIBRARY, CHAT, SEARCH, DOWNLOAD } } = CONSTANTS;
export class LayoutContainer extends PureComponent {

  componentDidMount = () => {
    // const { IS_WALKME_ENABLED } = CONFIG;
    // if (IS_WALKME_ENABLED) {
    //   this.walkMeInitialize();
    // }
    //this.getLoggedInUSerDetails();
    usabillaSetupFn();
  }

  getLoggedInUSerDetails = async () => {
    const hrid = await getUserId();
    const {
      peopleResultsActions: {
        getPeopleDataUsingIDs,
      },
      setLoggedInUserData
    } = this.props;
    const peopleData = await getPeopleDataUsingIDs([hrid]);
    if (peopleData && peopleData[0])
      setLoggedInUserData(peopleData[0]);
  }

  walkMeInitialize = () => {
    if (window) {
      const { API_URL: { WALKME_SCRIPT_URL } } = CONFIG;
      const walkme = document.createElement('script');
      walkme.type = 'text/javascript';
      walkme.async = true;
      walkme.src = WALKME_SCRIPT_URL;
      const s = document.getElementsByTagName('script')[0];
      if (!!s) {
        s.parentNode.insertBefore(walkme, s);
      }
      window._walkmeConfig = { smartLoad: true };
    }
  }

  changeRoute = (url = "/") => {
    const { history, activeRoute, getRetrievalSuccess, clearTranscriptSelected, chatClearAllMessages, chatSuccess, setChatHistoryID, resetAllParentTags } = this.props;
    getRetrievalSuccess([]);
    clearTranscriptSelected("");
    chatClearAllMessages("");
    chatSuccess({});
    setChatHistoryID(null);
    resetAllParentTags();
    sendToLocalStorage("eVar2ForPreviousPage", activeRoute === "Chat" ? "fromChatBot" : activeRoute === "KeywordSearch" ? "fromKeywordSearch" : "fromSingleTranscript");
    history.push(url);
  };

  render = () => {
    const {
      children,
      layoutClass,
      isBodyScrollable,
      clearChat,
      activeRoute,
      match,
      history,
      location,
      isChatEnabled
    } = this.props;

    return (
      <div className={`layout layout__page ${!isBodyScrollable ? 'layout--unscrollable' : ''}`}>
        <Entitlement401RedirectWrapper
          match={match}
          history={history}
          location={location}
        >
          <Header clearChat={clearChat} />
          <div className={`main`}>
            <div className="sidebar">
              <div className="black-bar">
                <div data-tip data-for='KeywordSearchIcon' className={`${activeRoute == "KeywordSearch" || activeRoute == "SingleTranscript" ? "sidebar-ele active" : "sidebar-ele"}`}
                  onClick={() => activeRoute !== "KeywordSearch" && activeRoute !== "SingleTranscript" && this.changeRoute(CONFIG.UI_URL.KEYWORD_SEARCH)}>
                  <Image src={KeywordSearchIcon} alt="logo" />
                </div>
                <Tooltip id="KeywordSearchIcon" delayHide={0}>Keyword Search</Tooltip>

                {isChatEnabled &&
                  <>
                    <div data-tip data-for='chatIcon' className={`${activeRoute == "Chat" ? "sidebar-ele active" : "sidebar-ele"}`}
                      onClick={() => activeRoute !== "Chat" && this.changeRoute(CONFIG.UI_URL.CHAT_BOX)}>
                      <Image src={chatIcon} alt="logo" />
                      {/* <div className="chat-info-icon">
                        <Image src={chatInfoIcon} alt="logo" />
                      </div> */}
                    </div>
                    <Tooltip id="chatIcon" delayHide={0}>Try the Gen AI based Chat to generate insights from transcripts!</Tooltip>
                  </>
                }
              </div>
              <div className="chathistory">
                <h4>Chat History</h4>
              </div>
            </div >
            <div className="right-sec">
              <Fragment>
                <ErrorBoundary>
                  <Container
                    className={`layout__container layout__fix ${layoutClass}`}
                    fluid
                  >
                    {children}
                  </Container>
                </ErrorBoundary>
              </Fragment>
            </div>

          </div >
          <AcknowledgementModal />
        </Entitlement401RedirectWrapper>

      </div >
    );
  };
}

LayoutContainer.propTypes = {
  children: PropTypes.node,
  layoutClass: PropTypes.string
};

LayoutContainer.defaultProps = {
  layoutClass: "",
};

const mapStateToProps = (state) => ({
  isChatEnabled: entitlementSelectors.getEntitlementValue(state, TRANSCRIPT_LIBRARY, CHAT),
});

const mapDispatchToProps = (dispatch) => ({
  peopleResultsActions: bindActionCreators({ ...peopleResultsActions }, dispatch),
  setLoggedInUserData: (data) => dispatch(setLoggedInUserData(data)),
  getRetrievalSuccess: (response) => dispatch(getRetrievalSuccess(response)),
  clearTranscriptSelected: (response) => dispatch(clearTranscriptSelected(response)),
  chatClearAllMessages: (response) => dispatch(chatClearAllMessages(response)),
  chatSuccess: (response) => dispatch(chatSuccess(response)),
  setChatHistoryID: (response) => dispatch(setChatHistoryID(response)),
  resetAllParentTags: (response) => dispatch(resetAllParentTags(response)),
});

export const Layout = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutContainer)
);